import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { uploadModel } from '../../services/mlflowService';

const UploadModel = () => {
  const [modelName, setModelName] = useState('');
  const [disease, setDisease] = useState("AML");
  const [useCase, setModelUseCase] = useState([]);
  const [description, setDescription] = useState('');
  const [file, setFile] = useState(null);
  const navigate = useNavigate();

  const handleDiseaseChange = (event) => {
    setDisease(event.target.value); // Set the selected disease value
  };

  const handleUseCaseChange = (event) => {
    const { value, checked } = event.target;
    setModelUseCase(prev => checked ? [...prev, value] : prev.filter(u => u !== value));
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]); // Update file state with selected file
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!disease) {
      alert("Please select a disease before submitting.");
      return;
    }

    const formData = new FormData();
    formData.append("model_name", modelName);
    formData.append("disease", disease);
    formData.append("use_case", useCase.join(', '));
    formData.append("file", file);
    formData.append("description", description);

    try {
      await uploadModel(formData);
      // Clear form and navigate after successful upload
      setModelName('');
      setDisease('AML');
      setModelUseCase([]);
      setDescription('');
      setFile(null);
      navigate('/models');
    } catch (error) {
      console.error("Failed to upload model:", error);
    }
  };

  return (
    <div class="contact">
      <section class="task-section">
        <h1>Upload model</h1>
        <form class="task-form" onSubmit={handleSubmit}>
          <label class="color-primary" htmlFor="model-name">Model name</label>
          <input
            type="text"
            id="model-name"
            placeholder="<model_name>"
            value={modelName}
            onChange={(e) => setModelName(e.target.value)}
            required
          />

          <label class="color-primary" htmlFor="description">Description</label>
          <textarea
            id="description"
            placeholder="<description>"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
          />

          <label class="color-primary" htmlFor="model-disease">Disease</label>
          <div id="model-disease">
            <input
                type="radio"
                id="aml"
                name="disease"
                value="AML"
                checked={disease === 'AML'}
                onChange={handleDiseaseChange}
                required
            />
            <label htmlFor="aml">AML</label><br/>
            <input
                type="radio"
                id="sdc"
                name="disease"
                value="SCD"
                checked={disease === 'SCD'}
                onChange={handleDiseaseChange}
                required
            />
            <label htmlFor="sdc">SCD</label><br/>
          </div>

          <label class="color-primary" htmlFor="model-use-case">Use Case</label>
          <div id="model-use-case">
            <input type="checkbox" id="classifier" name="use_case" value="Classifier" onChange={handleUseCaseChange} />
            <label htmlFor="aml">Classifier</label><br />
            <input type="checkbox" id="regression" name="use_case" value="Regression" onChange={handleUseCaseChange} />
            <label htmlFor="sdc">Regression</label><br />
            <input type="checkbox" id="gan" name="use_case" value="GAN" onChange={handleUseCaseChange} />
            <label htmlFor="sdc">GAN</label><br />
            <input type="checkbox" id="other" name="use_case" value="Other" onChange={handleUseCaseChange} />
            <label htmlFor="other">Other</label>
          </div>

          <label class="color-primary" htmlFor="model-file">Model File</label>
          <input type="file" id="model-file" onChange={handleFileChange} required />

          <div class="form-actions">
            <button type="submit" class="btn-yellow">Upload model</button>
            <button type="reset" class="btn-light-purple">Clear</button>
          </div>
        </form>
      </section>
    </div>
  );
};

export default UploadModel;
