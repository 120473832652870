import React, { useState } from 'react';
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";

const Network = ({ nodes, addNode, isAdmin, deleteNode }) => {
  const navigate = useNavigate();

  const handleGoToRegisterNode = () => {
    navigate('/network/register-node');
  };

  const handleDeleteNode = (index) => {
    deleteNode(index)
  };


  const nodeWidth = 100;
  const nodeHeight = 40;
  const spacing = 20;
  const nodesPerRow = 4;



  const renderNodes = () => {
    return nodes.map((node, index) => {
      const row = Math.floor(index / nodesPerRow); // Determine the row
      const col = index % nodesPerRow; // Determine the column
      const x = col * (nodeWidth + spacing) + 50; // Adjusted for alignment
      const y = row * (nodeHeight + spacing) + 220; // All nodes on the same baseline

      return (
        <g key={index}>
          <rect x={x} y={y} width={nodeWidth} height={nodeHeight} fill="#FFC107" stroke="#333" strokeWidth="1" />
          <text x={x + nodeWidth / 2} y={y + nodeHeight / 2 + 5} fontSize="14" textAnchor="middle" fill="#333">
            {node.name}
          </text>
        </g>
      );
    });
  };

  return (
    <div class="network">
      <div class="container">
        <h1>Federated Network</h1>
        {isAdmin && (
          <>
            <button class="btn-primary">
              Manage network
            </button>
            <Button onClick={handleGoToRegisterNode} class="btn-yellow ml-20">Register Node</Button>
          </>
        )}

        {/* Central Node Section */}
        <section class="central-node">
          <div class="flex-column w-100p">
            <h2>Central Node</h2>
            <div class="central-node-details flex-row flex-space-between">
              <div class="node-info frame-primary mt-20 h-100p">
                <p>Node Name: Central</p>
                <p>Node Location: Main Data Center</p>
                <p>Owner: UPM</p>
                <div class="node-log h-80p">
                    <p>2024/11/05 10:57:55 INFO mlflow.tracking._tracking_service.client: 🏃 View run global at: http://mlflow:5000/#/experiments/0/runs/971cd7e9d75e463582c6e54a8ade4aa6.</p>
                    <p>2024/11/05 10:57:55 INFO mlflow.tracking._tracking_service.client: 🧪 View experiment at: http://mlflow:5000/#/experiments/0.</p>
                    <p>2024/11/05 10:57:55 INFO mlflow.system_metrics.system_metrics_monitor: Stopping system metrics monitoring...</p>
                    <p>2024/11/05 10:57:55 INFO mlflow.system_metrics.system_metrics_monitor: Successfully terminated system metrics monitoring!</p>
                </div>
              </div>
              <div class="network-tree search-bar">
                <h3>Federated Network Tree</h3>
                <div class="frame-primary h-100p w-100p">
                  {/* SVG Tree Diagram */}
                  <svg width="100%" height="400" viewBox="0 0 500 400">
                    <svg width="100%" height="400" viewBox="0 0 500 400">
                        <rect x="200" y="20" width="100" height="40" fill="#4CAF50" stroke="#333" stroke-width="1" />
                        <text x="250" y="45" font-size="14" text-anchor="middle" fill="#fff">Central Node</text>

                        <line x1="250" y1="60" x2="150" y2="120" stroke="#333" stroke-width="1" />
                        <line x1="250" y1="60" x2="350" y2="120" stroke="#333" stroke-width="1" />


                        <rect x="100" y="120" width="100" height="40" fill="#2196F3" stroke="#333" strokeWidth="1" />
                        <text x="150" y="145" fontSize="14" textAnchor="middle" fill="#fff">Network 1</text>

                        <rect x="300" y="120" width="100" height="40" fill="#2196F3" stroke="#333" strokeWidth="1" />
                        <text x="350" y="145" fontSize="14" textAnchor="middle" fill="#fff">Network 2</text>

                        <rect x="100" y="120" width="100" height="40" fill="#2196F3" stroke="#333" stroke-width="1" />
                        <text x="150" y="145" font-size="14" text-anchor="middle" fill="#fff">Network 1</text>

                        <rect x="300" y="120" width="100" height="40" fill="#2196F3" stroke="#333" stroke-width="1" />
                        <text x="350" y="145" font-size="14" text-anchor="middle" fill="#fff">Network 2</text>

                        <line x1="150" y1="160" x2="100" y2="220" stroke="#333" stroke-width="1" />
                        <line x1="150" y1="160" x2="200" y2="220" stroke="#333" stroke-width="1" />
                        <line x1="350" y1="160" x2="300" y2="220" stroke="#333" stroke-width="1" />
                        <line x1="350" y1="160" x2="400" y2="220" stroke="#333" stroke-width="1" />

                        <rect x="50" y="220" width="100" height="40" fill="#FFC107" stroke="#333" stroke-width="1" />
                        <text x="100" y="245" font-size="14" text-anchor="middle" fill="#333">Node A</text>
                        <rect x="150" y="220" width="100" height="40" fill="#FFC107" stroke="#333" stroke-width="1" />
                        <text x="200" y="245" font-size="14" text-anchor="middle" fill="#333">Node B</text>

                        <rect x="250" y="220" width="100" height="40" fill="#FFC107" stroke="#333" stroke-width="1" />
                        <text x="300" y="245" font-size="14" text-anchor="middle" fill="#333">Node C</text>
                        <rect x="350" y="220" width="100" height="40" fill="#FFC107" stroke="#333" stroke-width="1" />
                        <text x="400" y="245" font-size="14" text-anchor="middle" fill="#333">Node D</text>
                    </svg>
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Edge Nodes Section */}
        <section class="edge-nodes">
          <div class="flex-space-between mt-100">
            <h2>Edge Nodes</h2>
            <div class="search-bar">
              <input type="text" placeholder="<search node>" />
              <button>🔍</button>
            </div>
          </div>
          {nodes.map((node, index) => (
            <div key={index} class="edge-node">
              <button class="btn-primary-text-left">{node.name}</button>
              <div class="edge-content flex-row">
                <div class="node-description">
                  <p>Node name: {node.name}</p>
                  <p>Node Location: {node.location}</p>
                  <p>Owner: {node.owner}</p>
                  <p>Last task involved: {node.last_task_involved}</p>
                  <p>Network: {node.network}</p>
                  <p>Created at: {node.created_at}</p>
                </div>
                <div class="node-log ml-10 mr-10">
                    <p>INFO :      [RUN 4996510757403527302, ROUND filter_message]</p>
                    <p>INFO :      Received: query message 18166622-6b51-41fd-a3df-1513b746fb8b</p>
                    <p>INFO :      Sent reply</p>
                    <p>INFO :      [RUN 4996510757403527302, ROUND set_run_config]</p>
                    <p>INFO :      Received: query message 20092c18-4791-4216-9f27-0b7edcfb0907</p>
                    <p>INFO :      [RUN 4996510757403527302, ROUND load_data]</p>
                    <p>INFO :      [RUN 4996510757403527302, ROUND load_model]</p>
                    <p>INFO :      [RUN 4996510757403527302, ROUND train_model]</p>
                    <p>INFO :      [RUN 4996510757403527302, ROUND upload_model]</p>
                </div>
                {isAdmin && (
                  <>
                    <button
                      class="btn-red ml-10"
                      onClick={() => handleDeleteNode(index)}
                    >
                    Delete
                  </button>
                </>
                )}
              </div>
            </div>
          ))}
        </section>

        <div class="pagination hide">
          <button>&laquo;</button>
          <button>1</button>
          <button>2</button>
          <button>3</button>
          <button>4</button>
          <button>5</button>
          <button>&raquo;</button>
        </div>
      </div>
    </div>
  );
};

export default Network;
