import React from 'react';
import { Container, Typography, Button, Grid } from '@mui/material';
import '../styles/About.css';

const About = () => {
  return (
    <div className="about">
      <Container>
        {/* Introduction Section */}
        <section className="intro-section">
          <Typography variant="h1" gutterBottom align="center">
            About Synthema
          </Typography>
          <Typography variant="body1" paragraph>
            Synthema is an innovative research project that focuses on the development of a
            distributed platform for federated learning and data privacy. This project enables
            the collaboration of multiple organizations, institutions, and data owners for
            training machine learning models on sensitive data while ensuring that data does
            not leave its local storage, thereby preserving privacy and compliance with
            regulations such as GDPR.
          </Typography>
        </section>

        {/* Project Goals Section */}
        <section className="goals-section">
          <Typography variant="h2" gutterBottom>
            Project Goals
          </Typography>
          <Typography variant="body1" paragraph>
            Synthema’s main goals are to:
          </Typography>
          <ul>
            <li>Develop cutting-edge federated learning algorithms</li>
            <li>Ensure data privacy and security in machine learning tasks</li>
            <li>Facilitate collaborative training among institutions without data exchange</li>
            <li>Make machine learning accessible to a wider range of industries</li>
            <li>Comply with European data protection regulations</li>
          </ul>
        </section>

        {/* Partners Section */}
        <section className="partners-section">
          <Typography variant="h2" gutterBottom>
            Our Partners
          </Typography>
          <Typography variant="body1" paragraph>
            Synthema brings together top institutions, researchers, and tech companies working on
            federated learning and privacy-preserving AI. Our partners include:
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={3}>
              <img src="https://synthema.eu/assets/images/logo1.png" alt="Partner 1" width="100%" />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <img src="https://synthema.eu/assets/images/logo2.png" alt="Partner 2" width="100%" />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <img src="https://synthema.eu/assets/images/logo3.png" alt="Partner 3" width="100%" />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <img src="https://synthema.eu/assets/images/logo4.png" alt="Partner 4" width="100%" />
            </Grid>
          </Grid>
        </section>

        {/* Link to More Information */}
        <section className="cta-section">
          <Typography variant="h2" gutterBottom>
            Learn More About Synthema
          </Typography>
          <Button
            variant="contained"
            href="https://synthema.eu/"
            target="_blank"
            rel="noopener noreferrer"
            className="btn-primary"
          >
            Visit Our Website
          </Button>
        </section>
      </Container>
    </div>
  );
};

export default About;