import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { createTask } from '../../services/taskService'; // Keep the original taskService import
import { getModels } from '../../services/mlflowService'; // Import getModels from mlflowService

const NewTask = () => {
  const [taskName, setTaskName] = useState('');
  const [disease, setDisease] = useState([]);
  const [model, setModel] = useState('');
  const [dataset, setDataset] = useState('');
  const [useCase, setUseCase] = useState([]);
  const [numGlobalIterations, setNumGlobalIterations] = useState(1);
  const [models, setModels] = useState([]); // State for models
  const [datasets, setDatasets] = useState([]); // State for datasets if needed
  const navigate = useNavigate();

  useEffect(() => {
    const fetchModels = async () => {
      try {
        const modelsData = await getModels(); // Fetch models
        setModels(modelsData);
      } catch (error) {
        console.error("Failed to fetch models:", error);
      }
    };

    fetchModels();
  }, []);

  const handleDiseaseChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setDisease((prev) => [...prev, value]);
    } else {
      setDisease((prev) => prev.filter((item) => item !== value));
    }
  };

  const handleUseCaseChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setUseCase((prev) => [...prev, value]);
    } else {
      setUseCase((prev) => prev.filter((item) => item !== value));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const newTask = {
      run_name: taskName,
      experiment_name: "Default",
      // disease: disease.join(', '),
      model_name: model,
      model_version: "1",
      // dataset,
      use_case: useCase.join(', '),
      num_global_iterations: numGlobalIterations,
      //status: 'Pending',
      user_id: '1',
    };

    try {
      await createTask(newTask);
      setTaskName('');
      setDisease([]);
      setModel('');
      setDataset('');
      setUseCase([]);
      setNumGlobalIterations(1);
      navigate('/tasks');
    } catch (error) {
      console.error('Failed to create task:', error);
    }
  };

  return (
    <div class="new-task">
      <section class="task-section">
        <h1>Create new Federated task</h1>

        <form class="task-form" onSubmit={handleSubmit}>
          <label class="color-primary" htmlFor="model-select">Model to use</label>
          <select id="model-select" value={model} onChange={(e) => setModel(e.target.value)} required>
            <option value="" disabled>Select model</option>
            {models.map((model, index) => (
              <option key={index} value={model.name}>{model.name}-{model.latest_versions[0].version}</option>
            ))}
          </select>

          <label class="color-primary" htmlFor="dataset-select">Available datasets</label>
          <select id="dataset-select" value={dataset} onChange={(e) => setDataset(e.target.value)} required>
            <option value="" disabled>Select dataset</option>
            {datasets.map((dataset, index) => (
              <option key={index} value={dataset.name}>{dataset.name}-{dataset.node_name}</option>
            ))}
            <option key="iris" value="iris">Iris</option>
            <option key="iris" value="iris">MNIST</option>
          </select>

          <label class="color-primary" htmlFor="model-disease">Disease</label>
          <div id="model-disease">
            <input type="checkbox" id="aml" name="disease" value="AML" onChange={handleDiseaseChange} />
            <label htmlFor="aml">AML</label><br />
            <input type="checkbox" id="sdc" name="disease" value="SDC" onChange={handleDiseaseChange} />
            <label htmlFor="sdc">SDC</label><br />
            <input type="checkbox" id="other" name="disease" value="Other" onChange={handleDiseaseChange} />
            <label htmlFor="other">Other</label>
          </div>

          <label class="color-primary" htmlFor="model-use-case">Use Case</label>
          <div id="model-use-case">
            <input type="checkbox" id="classifier" name="use_case" value="classifier" onChange={handleUseCaseChange} />
            <label htmlFor="classifier">Classifier</label><br />
            <input type="checkbox" id="regression" name="use_case" value="regression" onChange={handleUseCaseChange} />
            <label htmlFor="regression">Regression</label><br />
            <input type="checkbox" id="gan" name="use_case" value="iris" onChange={handleUseCaseChange} />
            <label htmlFor="gan">Iris</label><br />
            <input type="checkbox" id="other" name="use_case" value="other" onChange={handleUseCaseChange} />
            <label htmlFor="other">Other</label>
          </div>

          <label class="color-primary" htmlFor="task-name">Task name</label>
          <input
            type="text"
            id="task-name"
            value={taskName}
            onChange={(e) => setTaskName(e.target.value)}
            placeholder="<task_name>"
            required
          />

          <label class="color-primary" htmlFor="num-global-iterations">Number of Global Iterations</label>
          <input
            type="number"
            id="num-global-iterations"
            value={numGlobalIterations}
            onChange={(e) => setNumGlobalIterations(e.target.value)}
            placeholder="Enter number of global iterations"
            min="1"
            required
          />

          <div class="form-actions">
            <button type="submit" class="btn-yellow">Schedule task</button>
            <button type="reset" class="btn-light-purple">Clear</button>
          </div>
        </form>
      </section>
    </div>
  );
};

export default NewTask;
