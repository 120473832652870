import React, { useState } from 'react';
import { Build, Storage, NetworkWifi, ListAlt } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

const InfoBox = () => {
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState('tasks');

  const handleTabChange = (tab) => {
    setSelectedTab(tab);
  };

  const handleGoToTasks = () => {
    navigate('/tasks');
  };

  const handleGoToModels = () => {
    navigate('/models');
  };

  const handleGoToNetwork = () => {
    navigate('/network');
  };

  const handleGoToDatasets = () => {
    navigate('/datasets');
  };

  const handleGoToNewTask = () => {
    navigate('/tasks/new');
  };

  return (
    <div class="m-40 pl-20 pr-20 flex-center">
      {/* Left Column with Icons as Buttons */}
      <div class="tabs-column mt-5 maxw-240">
        <div
          class="tab-button flex-center"
          onClick={() => handleTabChange('tasks')}
        >
          <Build class={selectedTab === 'tasks' ? 'icon-primary' : 'icon-secondary'} />
          <span class={`ml-5 ${selectedTab === 'tasks' ? 'color-primary' : 'color-secondary'}`}>
            FL Tasks
          </span>
        </div>
        <div
          class="tab-button flex-center mt-5"
          onClick={() => handleTabChange('models')}
        >
          <Storage class={selectedTab === 'models' ? 'icon-primary' : 'icon-secondary'} />
          <span class={`ml-5 ${selectedTab === 'models' ? 'color-primary' : 'color-secondary'}`}>
            Models
          </span>
        </div>
        <div
          class="tab-button flex-center mt-5"
          onClick={() => handleTabChange('network')}
        >
          <NetworkWifi class={selectedTab === 'network' ? 'icon-primary' : 'icon-secondary'} />
          <span class={`ml-5 ${selectedTab === 'network' ? 'color-primary' : 'color-secondary'}`}>
            Network
          </span>
        </div>
        <div
          class="tab-button flex-center mt-5"
          onClick={() => handleTabChange('datasets')}
        >
          <ListAlt class={selectedTab === 'datasets' ? 'icon-primary' : 'icon-secondary'} />
          <span class={`ml-5 ${selectedTab === 'datasets' ? 'color-primary' : 'color-secondary'}`}>
            Datasets
          </span>
        </div>
      </div>

      {/* Right Column with Information Based on Selected Tab */}
      <div class="info-box" style={{ marginLeft: '20px', flexGrow: 1 }}>
        {selectedTab === 'tasks' && (
          <>
            <p>
              In here you can create and view the federated learning task by selecting a model from the available models
              and selecting an aggregated dataset, which is composed of the data chunks located in their respective
              nodes. The task will trigger the model to train in the nodes where data is located, automatically
              selecting the nodes with data corresponding to the selected use case.
            </p>
            <button onClick={handleGoToTasks} class="btn-light-purple">Go to federated tasks</button>
            <button onClick={handleGoToNewTask} class="btn-dark-purple ml-10">Create new task</button>
          </>
        )}

        {selectedTab === 'models' && (
          <>
            <p>In here you can upload and view the AI models, as well as inspect the different models distinguishing between the different versions and its last activities, as well as a record of timestamps for each version. The models are registered by different versions which can be inspected by selecting the desired model</p>
            <button onClick={handleGoToModels} class="btn-light-purple">Go to models</button>
          </>
        )}

        {selectedTab === 'network' && (
          <>
            <p>In here you can inspect and manage the federated network architecture and to have the status of the nodes and the data stored within it (only accesible from within the node). As an administrator, you will also be able to manage the network and register or unregister nodes to the network. You can also check the state of the nodes and its last activities.</p>
            <button onClick={handleGoToNetwork} class="btn-light-purple">Go to federated network</button>
          </>
        )}

        {selectedTab === 'datasets' && (
          <>
            <p>In here you can inspect and view the different datasets available for the platform. This content is restricted by the data contained within the different nodes and the management of the data and the datasets is not performed through this portal. To set up the addition of new data, a new request to administration shall be performed and processed</p>
            <button onClick={handleGoToDatasets} class="btn-light-purple">Go to datasets</button>
          </>
        )}
      </div>
    </div>
  );
};

export default InfoBox;
