import React, { useEffect, useState } from 'react';
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { getTasksAll, cancelTask } from '../../services/taskService';
import { getExperimentByName, getExperimentRuns } from '../../services/mlflowService';

const Tasks = () => {
  const navigate = useNavigate();
  const [tasks, setTasks] = useState([]);
  const [mlflowTasks, setMlflowTasks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchTasks = async () => {
    try {
      const tasksData = await getTasksAll();
      const mlflowTasksData = await fetchMLFlowTasks();

      setTasks(tasksData);
      setMlflowTasks(mlflowTasksData);
    } catch (err) {
      setError('Failed to fetch tasks');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const fetchMLFlowTasks = async () => {
    try {
      const experiments = await getExperimentByName("MNIST-Training");
      const experimentsArray = Array.isArray(experiments) ? experiments : [experiments];
      const experimentRuns = await Promise.all(
        experimentsArray.map((exp) => getExperimentRuns(exp.experiment_id))
      );

      const mlflowTasks = experimentRuns.flatMap((runs) =>
        runs.map((run) => ({
          id: run.info.run_id,
          run_name: run.info.run_name,
          model_name: run.info.artifact_uri,
          status: run.info.status,
          metrics: run.data.metrics,
          user: run.info.user_id,
          start_time: new Date(run.info.start_time).toLocaleString(),
          end_time: new Date(run.info.end_time).toLocaleString(),
          source_name: run.data.tags["mlflow.source.name"],
          source_type: run.data.tags["mlflow.source.type"]
        }))
      );

      return mlflowTasks;
    } catch (error) {
      console.error("Error fetching MLflow tasks:", error);
      return [];
    }
  };

  useEffect(() => {
    fetchTasks();
    const intervalId = setInterval(fetchTasks, 5000);
    return () => clearInterval(intervalId);
  }, []);

  const handleGoToNewTask = () => {
    navigate('/tasks/new');
  };

  const handleGoToTaskDetail = (task) => {
    navigate(`/tasks/detail/${task.id}`, { state: { task } });
  };

  const handleGoToMTaskDetail = (task) => {
    navigate(`/tasks/mdetail/${task.id}`, { state: { task } });
  };

  const handleCancelTask = async (taskId) => {
    try {
      await cancelTask(taskId);
      setTasks((prevTasks) =>
        prevTasks.map((task) =>
          task.id === taskId ? { ...task, status: 'Cancelled' } : task
        )
      );
    } catch (error) {
      setError('Failed to cancel task');
      console.error('Error canceling task:', error);
    }
  };

  const getStatusClass = (status) => {
    switch (status) {
      case 'running':
      case 'FINISHED':
        return 'green-status';
      case 'pending':
        return 'yellow-status';
      case 'FAILED':
      case 'CANCELLED':
        return 'red-status';
      default:
        return 'yellow-status';
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div class="tasks">
      <div class="container">
        <h1>Federated Learning Tasks</h1>
        <Button onClick={handleGoToNewTask} class="btn-primary">Schedule new Federated Task</Button>

        <div class="search-bar">
          <input type="text" placeholder="<search task>" />
          <button>🔍</button>
        </div>

        <h2>Recent tasks</h2>
        <div class="task-list">
          {tasks.length > 0 ? (
            tasks.map((task) => (
              <div key={task.id}>
                <button class="btn-primary-text-left">{task.run_name}</button>
                <div class="task-item">
                  <div class="details">
                    <div class="mr-10">
                      <p>Task Name: {task.run_name || 'No name available'}</p>
                      <p>Model Name: {task.model_name || 'No model name'}</p>
                      <p>Model Version: {task.model_version || 'No version'}</p>
                      <p>Use Case: {task.use_case || 'No use case'}</p>
                    </div>
                    <div class="ml-10">
                      <p>Status: {task.status || 'No status available'}</p>
                      <p>Global Iterations: {task.num_global_iterations || 'N/A'}</p>
                      <p>User ID: {task.user_id || 'N/A'}</p>
                      <p>Metrics: {task.data?.metrics ? "metrics available" : 'No metrics available'}</p>
                    </div>
                  </div>
                  <div class={getStatusClass(task.status)}>
                    {task.status?.toUpperCase() || 'UNKNOWN'}
                  </div>
                  <div class="actions">
                    <Button onClick={() => handleGoToTaskDetail(task)} class="btn-skin">Go to</Button>
                    <button onClick={() => handleCancelTask(task.id)} class="btn-red mt-10">Cancel task</button>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div></div>
          )}

          {mlflowTasks.length > 0 ? (
            mlflowTasks.map((task) => (
              <div key={task.id}>
                <button class="btn-primary-text-left">MNIST-Training</button>
                <div class="task-item">
                  <div class="details">
                    <div class="mr-10">
                      <p>Task Name: {'MNIST-Training' || 'No name available'}</p>
                      <p>Model Name: {'MNIST Simple NN' || 'No model name'}</p>
                      <p>Model Version: {'1' || 'No version'}</p>
                      <p>Use Case: {'MNIST' || 'No use case'}</p>
                    </div>
                    <div class="ml-10">
                      <p>Status: {task.status || 'No status available'}</p>
                      <p>Global Iterations: {'8' || 'N/A'}</p>
                      <p>User ID: {'santiagobl' || 'N/A'}</p>
                      <p>Metrics: {'Train Accuracy, Train Loss, Test Accuracy, Test Loss' || 'No status available'}</p>
                      {/*<ul>*/}
                      {/*  {task.metrics && Object.entries(task.metrics).map(([key, value]) => (*/}
                      {/*    <li key={key}>{key}: {value}</li>*/}
                      {/*  ))}*/}
                      {/*</ul>*/}
                    </div>
                  </div>
                  <div class={getStatusClass(task.status)}>
                    {task.status?.toUpperCase() || 'UNKNOWN'}
                  </div>
                  <div class="actions">
                    <Button onClick={() => handleGoToMTaskDetail(task)} class="btn-skin">Go to</Button>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p>No tasks available.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Tasks;
