import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getModelVersions } from '../../services/mlflowService'; // Assuming you have this function available

const ModelDetail = () => {
  const location = useLocation();
  const { model } = location.state || {}; // Get model from state passed by Models component

  const [versions, setVersions] = useState([]); // Store versions for the model
  const [loading, setLoading] = useState(true); // To handle loading state
  const [selectedVersion, setSelectedVersion] = useState(null); // Store the selected version

  useEffect(() => {
    if (model) {
      const fetchVersions = async () => {
        try {
          const versionsData = await getModelVersions(model.name); // Fetch versions using model name
          setVersions(versionsData); // Update versions state
          setSelectedVersion(versionsData[0]); // Default to the first version
        } catch (error) {
          console.error('Error fetching model versions:', error);
        } finally {
          setLoading(false); // Stop loading once data is fetched
        }
      };

      fetchVersions();
    }
  }, [model]); // This runs whenever the model changes (when navigating between models)

  if (!model) {
    return <div>No model data available</div>;
  }

  // Handle version selection
  const handleVersionChange = (event) => {
    const version = versions.find(v => v.version === event.target.value);
    setSelectedVersion(version);
  };

  return (
    <div class="model-det">
      <section class="model-detail">
        <h1>Model: {model.name}</h1>



        {/* Model Information Section */}
        <div class="model-v0 p-20 mt-20">
          <h2>General Information</h2>
          <div class="model-metadata">
            <p><strong>Model Name:</strong> {model.name}</p>
            <p><strong>Created At:</strong> {new Date(model.creation_timestamp).toLocaleString()}</p>
            <p><strong>Updated At:</strong> {new Date(model.last_updated_timestamp).toLocaleString()}</p>
            <p><strong>Use Case:</strong> {selectedVersion?.tags?.use_case || 'N/A'}</p>
          </div>
          <button class="btn-dark-purple mt-5">Download/Preview</button>
        </div>

        {/* Version Metadata Section */}
        <div class="version-info p-20 mt-20">
          {/* Dropdown for Version Selection */}
        <div class="version-selector flex-row">
          <label htmlFor="versionSelect">Select Version:</label>
          <select id="versionSelect" onChange={handleVersionChange} value={selectedVersion?.version || ''}>
            {versions.map((version) => (
              <option key={version.version} value={version.version}>
                Version {version.version}
              </option>
            ))}
          </select>
        </div>
          <h2>Version Information</h2>
          {loading ? (
            <p>Loading versions...</p>
          ) : selectedVersion ? (
            <>
              <div class="model-metadata">
                <p><strong>Version:</strong> {selectedVersion?.version}</p>
                <p><strong>Current Stage:</strong> {selectedVersion?.current_stage}</p>
                <p><strong>Run ID:</strong> {selectedVersion?.run_id}</p>
                <p><strong>Updated At:</strong> {new Date(selectedVersion?.last_updated_timestamp).toLocaleString()}</p>
                <p><strong>Source:</strong> {selectedVersion?.source}</p>
                <p><strong>Use Case:</strong> {selectedVersion?.tags?.use_case || 'N/A'}</p>
                <p><strong>Trained:</strong> {selectedVersion?.tags?.trained || 'N/A'}</p>
              </div>
              <button class="btn-skin mt-5 hide">Go to task</button>
            </>
          ) : (
              <p>No versions available.</p>
          )}
        </div>

        {/* Optional Performance Interactions/Charts */}
        <div class="hide">
          <h2>Interactions / Performance</h2>
          <div class="performance">
            {/*<img src="chart1.png" alt="Chart 1" />*/}
          </div>
        </div>

        {/* Use Case / Data Information */}
        <h2 class="hide">Use Case / Data</h2>
        <div class="model-metadata hide">
          <p><strong>Use Case:</strong> {selectedVersion?.tags?.use_case || 'N/A'}</p>
          <p><strong>Nodes:</strong> Node 1, Node 2</p> {/* Placeholder nodes */}
          <p><strong>Dataset:</strong> Iris</p> {/* Placeholder dataset */}
        </div>
      </section>
    </div>
  );
};

export default ModelDetail;
