import React, { useEffect, useState } from 'react';
import MnistMetricsTable from '../parts/MnistMetricsTable';
import { useParams, useNavigate } from 'react-router-dom';
import {getTaskById, getTasksAll} from '../../services/taskService';
import {getExperimentByName, getExperimentRuns, getModel} from '../../services/mlflowService';

const MnistTaskDetail = () => {
  const { task_id } = useParams();
  const [task, setTask] = useState(null);
  const [model, setModel] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [mlflowTasks, setMlflowTasks] = useState([]);

 const fetchMLFlowTasks = async () => {
    try {
      const experiments = await getExperimentByName("MNIST-Training");
      const experimentsArray = Array.isArray(experiments) ? experiments : [experiments];
      const experimentRuns = await Promise.all(
        experimentsArray.map((exp) => getExperimentRuns(exp.experiment_id))
      );

      const mlflowTasks = experimentRuns.flatMap((runs) =>
        runs.map((run) => ({
          id: run.info.run_id,
          run_name: run.info.run_name,
          model_name: run.info.artifact_uri,
          status: run.info.status,
          metrics: run.data.metrics,
          user: run.info.user_id,
          start_time: new Date(run.info.start_time).toLocaleString(),
          end_time: new Date(run.info.end_time).toLocaleString(),
          source_name: run.data.tags["mlflow.source.name"],
          source_type: run.data.tags["mlflow.source.type"]
        }))
      );

      return mlflowTasks;
    } catch (error) {
      console.error("Error fetching MLflow tasks:", error);
      return [];
    }
  };

 const fetchTasks = async () => {
    try {
      const mlflowTasksData = await fetchMLFlowTasks();

      setMlflowTasks(mlflowTasksData);
    } catch (err) {
      setError('Failed to fetch tasks');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  fetchTasks()

  const handleGoBack = () => {
    navigate(-1); // Go back to the previous page
  };


  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div class="task-detail">
      <section class="model-detail">
        <h1>Task: MNIST-Training</h1>
        <span class="green-status">FINISHED</span>
        <button class="btn-skin ml-15" onClick={handleGoBack}>Go back</button>
        <div class="model-v0 p-20 mt-10">
          <div class="model-metadata">
            <p>Task Name: MNIST-Training</p>
            <p>Rounds: 8 </p>
            <p>Status: finished</p>
            <p>Metrics: {'Train Accuracy, Train Loss, Test Accuracy, Test Loss' }</p>
            <p>Start time: {mlflowTasks[0].start_time}</p>
            <p>End time: {mlflowTasks[0].end_time}</p>
          </div>

          <div class="model-section">
            <h2>Model</h2>
            <div class="model-metadata">
              <p>Model Name: MNIST Simple NN</p>
              <p>Use case: MNIST</p> {/* Display use_case from model */}
              <p>Disease: { 'N/A'}</p> {/* Display disease from model */}
            </div>
            <button class="btn-skin mt-10">Go to model</button>
          </div>

          <div class="data-section">
            <h2>Data</h2>
            <div class="model-metadata">
              <p>Dataset: MNIST</p>
              <p>Nodes: Node 1, Node 2</p>
            </div>
            <button class="btn-skin mt-10 hide">Go to dataset</button>
          </div>

          <div class="metrics-section">
            <h2>Metrics</h2>
            <div class="metrics-table">
              <MnistMetricsTable />
            </div>
            <div class="charts flex-column">
              <div class="flex-row">
                <div>
                  <p class="color-secondary">Train Accuracy</p>
                    <iframe
                        src="https://grafana.synthema.gatv.es/d-solo/fe40rrkt08r9cd/new-dashboard?from=1731665790000&to=1731665879000&timezone=browser&var-query0=&var-metric=train_accuracy&var-query0-2=&var-run_uuid=d40d91487643485f9aa32347272e9032&orgId=1&theme=light&panelId=2&__feature.dashboardSceneSolo"
                        width="450" height="200" frameborder="0"></iframe>
                </div>
                  <div class="ml-10">
                      <p class="color-secondary">Train Loss</p>
                      <iframe
                          src="https://grafana.synthema.gatv.es/d-solo/fe40rrkt08r9cd/new-dashboard?from=1731665790000&to=1731665879000&timezone=browser&var-query0=&var-metric=train_loss&var-query0-2=&var-run_uuid=d40d91487643485f9aa32347272e9032&orgId=1&theme=light&panelId=2&__feature.dashboardSceneSolo"
                          width="450" height="200" frameborder="0"></iframe>
              </div>
            </div>

              <div class="flex-row">
                  <div>
                      <p class="color-secondary">Test Accuracy</p>
                      <iframe
                          src="https://grafana.synthema.gatv.es/d-solo/fe40rrkt08r9cd/new-dashboard?from=1731665790000&to=1731665879000&timezone=browser&var-query0=&var-metric=test_accuracy&var-query0-2=&var-run_uuid=d40d91487643485f9aa32347272e9032&orgId=1&theme=light&panelId=2&__feature.dashboardSceneSolo"
                          width="450" height="200" frameborder="0"></iframe>
                  </div>
                  <div class="ml-10">
                      <p class="color-secondary">Test Loss</p>
                      <iframe
                          src="https://grafana.synthema.gatv.es/d-solo/fe40rrkt08r9cd/new-dashboard?from=1731665790000&to=1731665879000&timezone=browser&var-query0=&var-metric=test_loss&var-query0-2=&var-run_uuid=d40d91487643485f9aa32347272e9032&orgId=1&theme=light&panelId=2&__feature.dashboardSceneSolo"
                          width="450" height="200" frameborder="0"></iframe>
              </div>
            </div>


          </div>
        </div>
    </div>
</section>
</div>
)
    ;
};

export default MnistTaskDetail;
