import React, { useEffect, useState } from 'react';
import MetricsTable from '../parts/MetricsTable';
import { useParams, useNavigate } from 'react-router-dom';
import { getTaskById } from '../../services/taskService';
import { getModel } from '../../services/mlflowService';

const TaskDetail = () => {
  const { task_id } = useParams();
  const [task, setTask] = useState(null);
  const [model, setModel] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTaskAndModel = async () => {
      try {
        const taskData = await getTaskById(task_id);  // Fetch task data
        setTask(taskData);

        // Fetch the model data based on the task's model name
        const modelData = await getModel(taskData.model_name);
        setModel(modelData);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchTaskAndModel();
  }, [task_id]); // Re-run when task_id changes

  const handleGoBack = () => {
    navigate(-1); // Go back to the previous page
  };

  const handleGoToModelDetail = () => {
    // Pass the full model data to ModelDetail.js
    navigate(`/models/detail/${task.model_name}`, { state: { model } });
  };

  const getStatusClass = (status) => {
    switch (status) {
      case 'running':
      case 'success':
        return 'green-status';
      case 'pending':
        return 'yellow-status';
      case 'failed':
      case 'cancelled':
        return 'red-status';
      default:
        return 'yellow-status'; // Default to 'yellow-status' for unknown statuses
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!task) {
    return <div>No task found.</div>;
  }

  return (
    <div class="task-detail">
      <section class="model-detail">
        <h1>Task: {task.run_name}</h1>
        <span class={getStatusClass(task.status)}>{task.status.toUpperCase()}</span>
        <button class="btn-skin ml-15" onClick={handleGoBack}>Go back</button>
        <div class="model-v0 p-20">
          <div class="model-metadata">
            <p>Task Name: {task.run_name}</p>
            <p>Rounds: {task.num_global_iterations}</p>
            <p>Status: {task.status}</p>
            <p>Metrics: Accuracy, Loss</p>
            <p>Created at: {task.created_at}</p>
          </div>

          <div class="model-section">
            <h2>Model</h2>
            <div class="model-metadata">
              <p>Model Name: {task.model_name}</p>
              <p>Use case: {model?.use_case || 'N/A'}</p> {/* Display use_case from model */}
              <p>Disease: {model?.disease || 'N/A'}</p> {/* Display disease from model */}
              <p>Aggregation Strategy: {model?.aggregation_strategy || 'N/A'}</p>
            </div>
            <button onClick={handleGoToModelDetail} class="btn-skin mt-5">Go to model</button>
          </div>

          <div class="data-section">
            <h2>Data</h2>
            <div class="model-metadata">
              <p>Dataset: Iris</p>
              <p>Nodes: Node 1, Node 2</p>
            </div>
            <button class="btn-skin mt-5 hide">Go to dataset</button>
          </div>

          <div class="metrics-section">
            <h2>Metrics</h2>
            <div class="metrics-table">
              <MetricsTable />
            </div>
            <div class="charts hide">
              <iframe
                  src="http://localhost:3001/d-solo/ae1xo1p9pglxcd/param-panel?from=1729856475000&to=1729856492000&timezone=browser&var-metric=train_loss&var-run_uuid=9fb1d48b928244ac898336ed4231d8ca&orgId=1&panelId=1&__feature.dashboardSceneSolo"
                  width="450" height="200" frameborder="0"></iframe>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default TaskDetail;
