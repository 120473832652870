import axios from 'axios';

const BASE_URL = `${window.env.REACT_APP_MLFLOW_API_BASE_URL}`;
console.log("Mlflow-api-url");
console.log(BASE_URL);

// Utility to handle API calls with Axios
const apiCall = async (url, options = {}) => {
  try {
    const response = await axios({
      url: `${BASE_URL}${url}`,
      method: options.method || 'GET',
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        'Accept': 'application/json',  // Added Accept header
        ...options.headers,  // Merge additional headers if needed
      },
      data: options.body || null,  // Add body for POST/PUT requests
    });
    return response.data;
  } catch (error) {
    console.error('API call error:', error);
    throw error;  // Rethrow the error to handle it in the calling function
  }
};

// Runs Endpoints
export const getRun = async (experimentId, runId) => {
  return apiCall(`/runs/${experimentId}/${runId}`);
};

export const getRunArtifacts = async (runId) => {
  return apiCall(`/runs/artifacts/${runId}`);
};

export const getRunMetrics = async (runId) => {
  return apiCall(`/runs/metrics/${runId}`);
};

export const deleteRun = async (runId) => {
  return apiCall(`/runs/${runId}`, {
    method: 'DELETE',
  });
};

export const cancelRun = async (runId) => {
  return apiCall(`/runs/${runId}/cancel`, {
    method: 'POST',
  });
};

// Experiments Endpoints
export const getExperiments = async () => {
  return apiCall(`/experiments/`);
};

export const getExperimentById = async (experimentId) => {
  return apiCall(`/experiments/${experimentId}`);
};

export const getExperimentByName = async (experimentName) => {
  return apiCall(`/experiments/name/${experimentName}`);
};

export const getExperimentRuns = async (experimentId) => {
  return apiCall(`/experiments/runs/${experimentId}`);
};

// Models Endpoints
export const getModels = async () => {
  return apiCall(`/models/`);
};

export const getModel = async (modelName) => {
  return apiCall(`/models/${modelName}`);
};

export const getModelVersions = async (modelName) => {
  return apiCall(`/models/${modelName}/versions`);
};

export const uploadModel = async (formData) => {
  try {
    const response = await axios.post(`${BASE_URL}/models/upload`, formData, {
      headers: {
        'Accept': 'application/json',
      },
    });

    return response.data; // Return the data from the response
  } catch (error) {
    console.error('Error uploading model:', error);
    throw error; // Rethrow the error to handle it in the calling function
  }
};


export const downloadModel = async (modelName, version) => {
  try {
    const response = await axios.get(`${BASE_URL}/models/download/${modelName}/${version}`, {
      responseType: 'arraybuffer', // To handle binary data correctly
      headers: {
        'Accept': 'application/json',  // Added Accept header for download
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error downloading model:", error);
    throw error;
  }
};

export const fetchModelVersions = async (modelName) => {
  return getModelVersions(modelName);  // Uses the same `getModelVersions` function
};

// Model version manipulation
export const deleteModelVersion = async (modelName, version) => {
  return apiCall(`/models/${modelName}/versions/${version}`, {
    method: 'DELETE',
  });
};

export const deleteModel = async (modelName) => {
  return apiCall(`/models/${modelName}`, {
    method: 'DELETE',
  });
};
