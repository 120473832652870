import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function createData(train_accuracy, train_loss, test_accuracy, test_loss) {
  return { train_accuracy, train_loss, test_accuracy, test_loss };
}

const rows = [
  createData('0.99', '0.94', '0.98', '0.07'),
];

const MnistMetricsTable = () => {
  return (
    <TableContainer component={Paper}  className="versions-table p-15">
      <Table sx={{ }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Train Accuracy</StyledTableCell>
            <StyledTableCell>Test Accuracy</StyledTableCell>
            <StyledTableCell>Train Loss</StyledTableCell>
            <StyledTableCell>Test Loss</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow key={row.metric_loss}>
              <StyledTableCell align="center">{row.train_accuracy}</StyledTableCell>
              <StyledTableCell align="center">{row.train_loss}</StyledTableCell>
              <StyledTableCell align="center">{row.test_accuracy}</StyledTableCell>
              <StyledTableCell align="center">{row.test_loss}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default MnistMetricsTable;