import React from 'react';

const Profile = () => {
  return (
    <div className="home">
      <div class="container flex-column flex-center">
        <h1 class="pl-20">Profile</h1>
        <div class="m-40 pl-20 pr-20">
          <div class="info-box">
            <p>Username: Silvia Uribe</p>
            <p>Email: sum@gatv.ssr.upm.es.com</p>
            <p>Organization: UPM</p>
            <p>Role: Administrator</p>
            <button class="ml-10 btn-primary">Edit</button>
            <button class="ml-10 btn-yellow">Administration</button>
            <button class="ml-10 btn-red">Change Password</button>
          </div>
        </div>

      </div>
    </div>
  );
};

export default Profile;