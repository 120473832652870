import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { styled } from '@mui/system';
import { useNavigate } from "react-router-dom";

// Styled components using Material UI's styled API
const SwiperContainer = styled(Swiper)({
  width: '100%',
  maxWidth: '600px',
  margin: 'auto',
});

const SlideImageContainer = styled('div')({
  position: 'relative',
});

const SlideImage = styled('img')({
  width: '100%',
  height: 'auto',
  borderRadius: '8px',
});

const TextOverlay = styled('div')({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  color: '#FF506F',
  backgroundColor: '#F2F2F2', // semi-transparent background
  padding: '10px 20px',
  borderRadius: '4px',
  textAlign: 'center',
});

const ImageSlider = ({ slides }) => {

  const navigate = useNavigate();



  const handleGoToContact = () => {
    navigate('/contact');
  };

  const handleGoToHelp = () => {
    navigate('/help');
  };

  const handleGoToAbout = () => {
    navigate('/about');
  };

  return (
    <SwiperContainer
      spaceBetween={10}
      slidesPerView={1}
      navigation
      pagination={{ clickable: true }}
    >
      {slides.map((slide, index) => (
        <SwiperSlide key={index}>
          <SlideImageContainer>
            <SlideImage src={slide.image} alt={`Slide ${index}`} />
            <TextOverlay>
              {slide.text}
              <div class="mt-10">
                <button onClick={handleGoToContact} class="btn-yellow mr-5">Contact</button>
                <button onClick={handleGoToAbout} class="btn-light-purple ml-5">About</button>
              </div>
            </TextOverlay>

          </SlideImageContainer>
        </SwiperSlide>
      ))}
    </SwiperContainer>
  );
};

export default ImageSlider;
