import axios from 'axios';

const BASE_URL = `${window.env.REACT_APP_FL_API_BASE_URL}`;
console.log("FL-api-url");
console.log(BASE_URL);

// const apiCall = async (url, options = {}) => {
//   try {
//     const response = await axios({
//       url: `${BASE_URL}${url}`,
//       method: options.method || 'GET',
//       headers: {
//         'Content-Type': 'application/json;charset=UTF-8',
//         'Accept': 'application/json',  // Added Accept header
//         ...options.headers,  // Merge additional headers if needed
//       },
//       data: options.body || null,  // Add body for POST/PUT requests
//     });
//     return response.data;
//   } catch (error) {
//     console.error('API call error:', error);
//     throw error;  // Rethrow the error to handle it in the calling function
//   }
// };

// Fetch all tasks
export const getTasksAll = async () => {
  return axios.get(`${BASE_URL}/tasks/`)
        .then(function (response) {
            console.log(response.data);
            return response.data
        })
};


export const getTaskById = async (taskId) => {
  return axios.get(`${BASE_URL}/tasks/${taskId}`)
        .then(function (response) {
            console.log(response.data);
            return response.data
        })
};


export const createTask = async (taskData) => {
  return axios.post(`${BASE_URL}/tasks/`, taskData)
        .then(function (response) {
            console.log(response.data);
            return response.data
        })
};



export const cancelTask = async (taskId) => {
  return axios.put(`${BASE_URL}/tasks/cancel/${taskId}`)
        .then(function (response) {
            console.log(response.data);
            return response.data
        })
};


// export const getTasksAll = async () => {
//   try {
//     const response = await apiClient.get('/tasks');
//     return response.data;
//   } catch (error) {
//     console.error('Failed to fetch tasks:', error);
//     throw error;
//   }
// };

// Fetch a task by ID
// export const getTaskById = async (taskId) => {
//   try {
//     const response = await apiClient.get(`/tasks/${taskId}`);
//     return response.data;
//   } catch (error) {
//     console.error(`Failed to fetch task with ID ${taskId}:`, error);
//     throw error;
//   }
// };

// Fetch tasks by user ID
// export const getTasksByUser = async (userId) => {
//   try {
//     const response = await apiClient.get(`/tasks/user/${userId}`);
//     return response.data;
//   } catch (error) {
//     console.error(`Failed to fetch tasks for user ${userId}:`, error);
//     throw error;
//   }
// };
//
// // Create a new task
// export const createTask = async (taskData) => {
//   try {
//     const response = await apiClient.post('/tasks', taskData);
//     return response.data;
//   } catch (error) {
//     console.error('Failed to create task:', error);
//     throw error;
//   }
// };
//
// // Cancel a task by ID
// export const cancelTask = async (taskId) => {
//   try {
//     const response = await apiClient.put(`/tasks/cancel/${taskId}`);
//     return response.data;
//   } catch (error) {
//     console.error(`Failed to cancel task with ID ${taskId}:`, error);
//     throw error;
//   }
// };
//
// // Delete all tasks
// export const deleteAllTasks = async () => {
//   try {
//     const response = await apiClient.delete('/tasks/');
//     return response.data;
//   } catch (error) {
//     console.error('Failed to delete all tasks:', error);
//     throw error;
//   }
// };
