import React from 'react';

const Footer = () => {
  return (
      <footer className="footer">
          <div className="footer-content flex-row p-20">
              <div class="flex-column p-20">
                  <img class="margin-auto maxw-260" src="/images/Funded_Disclaimer_Footer--EU-300x67-1.png" alt="Funded EU"/>
                  <p>SYNTHEMA is an initiative funded by the European Union’s Horizon Europe Research and
                      Innovation programme under grant agreement N° 101095530</p>
              </div>
              <div class="flex-column p-20 ml-20">
                  <img class="margin-auto maxw-120" src="/images/SYNTHEMA_Logo_Square.png" alt="Synthema"/>
                  <ul className="footer-links">
                      <li><a href="#">Privacy Policy</a></li>
                      <li><a href="#">Terms of Use</a></li>
                  </ul>
                  <p>©SYNTHEMA 2023. All rights reserved</p>
              </div>
          </div>
      </footer>
  );
};

export default Footer;
