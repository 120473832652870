import React, { useState } from 'react';
import {useNavigate} from "react-router-dom";

const RegisterNode = ({ registerNode }) => {
  // Local state for form inputs
  const [nodeName, setNodeName] = useState('');
  const [nodeLocation, setNodeLocation] = useState('');
  const [nodeNetwork, setNodeNetwork] = useState('');
  const [owner, setOwner] = useState('');
  const navigate = useNavigate();

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    // Construct a new node object
    const newNode = {
      name: nodeName,
      location: nodeLocation,
      owner: owner,
      last_task_involved: 'None', // Default value or add input
      network: nodeNetwork,
      created_at: new Date().toISOString().split('T')[0] // Set today's date
    };

    // Register the new node via the provided function
    registerNode(newNode);

    // Clear the form
    setNodeName('');
    setNodeLocation('');
    setNodeNetwork('');
    setOwner('');
    navigate("/network")
  };

  return (
    <div class="register-node">
      <section class="task-section">
        <h1>Register Node</h1>

        <form class="task-form" onSubmit={handleSubmit}>
          <label class="color-primary" htmlFor="node-name">Node name</label>
          <input
            type="text"
            id="node-name"
            placeholder="<node_name>"
            value={nodeName}
            onChange={(e) => setNodeName(e.target.value)}
          />

          <label class="color-primary" htmlFor="node-location">Node URL</label>
          <input
            type="text"
            id="node-location"
            placeholder="<node_location>"
            value={nodeLocation}
            onChange={(e) => setNodeLocation(e.target.value)}
          />

          <label class="color-primary" htmlFor="node-network">Node network</label>
          <input
            type="text"
            id="node-network"
            placeholder="<node_network>"
            value={nodeNetwork}
            onChange={(e) => setNodeNetwork(e.target.value)}
          />

          <label class="color-primary" htmlFor="owner">Owner</label>
          <input
            type="text"
            id="owner"
            placeholder="<owner>"
            value={owner}
            onChange={(e) => setOwner(e.target.value)}
          />

          <div class="form-actions">
            <button type="submit" class="btn-yellow">Register Node</button>
            <button type="reset" class="btn-light-purple ml-10" onClick={() => {
              setNodeName('');
              setNodeLocation('');
              setNodeNetwork('');
              setOwner('');
            }}>Clear</button>
          </div>
        </form>
      </section>
    </div>
  );
};

export default RegisterNode;
