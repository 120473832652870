// Datasets.js

import React from 'react';
import Button from '@mui/material/Button';
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

const Datasets = ({ datasets }) => {

  const handleDropdown = (el) => {
    let elem = document.getElementById("det".concat(String(el)));
    if (elem) {
      if (elem.classList.contains("show")) {
        elem.classList.remove("show");
        elem.classList.add("hide");
      } else if (elem.classList.contains("hide")) {
        elem.classList.remove("hide");
        elem.classList.add("show");
      }
    }
  };

  return (
    <div class="datasets">
      <div class="container">
        <div class="flex-space-between">
          <h1>Available datasets</h1>
          <div class="search-bar">
            <input type="text" placeholder="<search dataset>" />
            <button>🔍</button>
          </div>
        </div>
        <br />
        <div class="mt-15 flex-column">
          {/* Dynamically render each dataset */}
          {datasets.map((dataset, index) => (
            <div key={index}>
              <Button class="btn-primary-text-left" onClick={() => handleDropdown(index + 1)}>
                {dataset.name}
                <ArrowBackIosIcon class="arrow-icon-down hide" />
              </Button>
              <div id={`det${index + 1}`} class="flex-row flex-space-between p-15 frame-primary">
                <div class="model-metadata">
                  <p><strong>Dataset Name:</strong> {dataset.name}</p>
                  <p><strong>Dataset Nodes:</strong> {dataset.node_name}</p>
                  <p><strong>Dataset Location:</strong> {dataset.location}</p>
                  <p><strong>Dataset Disease:</strong> {dataset.disease || "AML"}</p>
                  <p><strong>Use Case:</strong> {dataset.use_case}</p>
                  <p><strong>Created At:</strong> {dataset.created_at}</p>
                  <p><strong>Updated At:</strong> {dataset.updated_at}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div class="pagination hide">
          <button>&laquo;</button>
          <button>1</button>
          <button>2</button>
          <button>3</button>
          <button>4</button>
          <button>5</button>
          <button>&raquo;</button>
        </div>
      </div>
    </div>
  );
};

export default Datasets;