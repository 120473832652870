import React from 'react';
import ModelTrainingIcon from '@mui/icons-material/ModelTraining';
import HubIcon from '@mui/icons-material/Hub';
import { useNavigate } from 'react-router-dom';
import Button from "@mui/material/Button";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import ImageSlider from "./parts/ImageSlider"
import InfoBox from "./parts/InfoBox"



const Home = () => {

  const navigate = useNavigate();

  const images = [
    '/images/SYNTHEMA_Background_Politecnica.png',
    '/images/SYNTHEMA_Banner_01.png',
    '/images/SYNTHEMA_Banner_02.png',
  ];
  const slides = [
    { image: '/images/SYNTHEMA_Background_Politecnica.png', text: 'Need to contact us? Need some advice?', btn_contact: "Contact", btn_help: "Help" },
    // { image: '/images/SYNTHEMA_Background_Politecnica.png', text: 'Do you want to know more about us?' },
    //{ image: '/images/SYNTHEMA_Banner_02.png', text: 'Third Slide' },
  ];

  const handleGoToTasks = () => {
    navigate('/tasks');
  };

  const handleGoToModels = () => {
    navigate('/models');
  };

  const handleGoToNetwork = () => {
    navigate('/network');
  };

  const handleGoToDatasets = () => {
    navigate('/datasets');
  };

  const handleGoToContact = () => {
    navigate('/contact');
  };

  const handleGoToHelp = () => {
    navigate('/help');
  };

  const handleGoToAbout = () => {
    navigate('/about');
  };

  const handleGoToTaskDetail = (task) => {
    navigate(`/tasks/detail/${task.task_name}`, { state: { task } });
  };

  const handleGoToModelDetail = (model) => {
    navigate(`/models/detail/${model.model_name}`, { state: { model } });
  };


  const handleGoToNewTask = () => {
    navigate('/tasks/new');
  };

  let handleTab = (el) => {
    let elem = document.getElementById("info-".concat(String(el)));
    if (elem) {
          if (elem.classList.contains("show")) {
              elem.classList.remove("show");
              elem.classList.add("hide");
          } else if (elem.classList.contains("hide")) {
              elem.classList.remove("hide");
              elem.classList.add("show");
          }
      }
    let button = document.getElementById("btn-".concat(String(el)));
    let button1 = document.getElementById("btn-tasks");
    let button2 = document.getElementById("btn-models");
    let button3 = document.getElementById("btn-network");
    let button4 = document.getElementById("btn-datasets");
    if (button1.classList.contains("active")) { button1.classList.remove("active");}
    if (button2.classList.contains("active")) { button2.classList.remove("active");}
    if (button3.classList.contains("active")) { button3.classList.remove("active");}
    if (button4.classList.contains("active")) { button4.classList.remove("active");}
    if (button) {button.classList.add("active")}
  };

  return (
    <div className="home">
      <div class="container">
        <h1 class="pl-20 flex-center">Welcome Silvia</h1>
        <InfoBox  />
        <div class="recent-activity">
          <h2>Your recent activity</h2>
          <div class="task-item">
            <ModelTrainingIcon class="icon-secondary p-5" />
            <div class="details flex-column ml-15">
              <p>MNIST-Training</p>
              <p>Status: Finished</p>
              <p>Created at: 11/15/2024</p>
              <p>Model: MNIST Simple NN</p>
              <p>Use case: MNIST</p>
            </div>
            <div class="actions ml-20">
              <Button onClick={handleGoToTaskDetail} class="btn-skin hide">Go to</Button>
            </div>
          </div>
          <div class="task-item">
            <HubIcon class="icon-secondary p-10" />
            <div class="details flex-column ml-15">
              <p>iris_model</p>
              <p>Version: 2</p>
              <p>Updated at: 10/10/2024</p>
              <p>Uploaded by: santiagobl</p>
              <p>Use case: Iris</p>
            </div>
              <div class="actions ml-20">
                  <Button onClick={handleGoToModelDetail} class="btn-skin hide">Go to</Button>
              </div>
          </div>
        </div>
        <ImageSlider slides={slides} />

      </div>
    </div>
  );
};

export default Home;
