import React from 'react';

const Help = () => {
  return (
    <div className="home">
      <div class="container">
        <h1 class="pl-20">Help</h1>
        <div class="m-40 pl-20 pr-20">
          <div class="info-box">

          </div>
        </div>

      </div>
    </div>
  );
};

export default Help;