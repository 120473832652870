import logo from './logo.svg';
import { useState } from 'react';
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './styles/App.css';
import Layout from './layout/Layout';
import Home from './components/Home';
import Models from './components/models/Models';
import ModelDetail from "./components/models/ModelDetail";
import UploadModel from "./components/models/UploadModel";
import Tasks from './components/tasks/Tasks';
import TaskDetail from './components/tasks/TaskDetail';
import MnistTaskDetail from './components/tasks/MnistTaskDetail';
import NewTask from './components/tasks/NewTask';
import Network from './components/network/Network';
import RegisterNode from "./components/network/RegisterNode";
import Datasets from './components/datasets/Datasets';
import Profile from './components/Profile';
import Contact from './components/Contact';
import About from './components/About';
import Help from './components/Help';

function App() {

  const [datasets, setDatasets] = useState([
    // Initial dummy data for datasets
        {
          name: 'Iris',
          node_name: 'Node A',
          disaese: 'AML',
          use_case: 'iris',
          location: 'Test node',
          created_at: '2024-01-01',
          updated_at: '2024-01-02',
        },
        {
          name: 'Iris',
          node_name: 'Node B',
          disease: 'AML',
          use_case: 'iris',
          location: 'Health Data Center B',
          created_at: '2024-02-01',
          updated_at: '2024-02-02',
        },
        {
          name: 'MNIST',
          node_name: 'Node A',
          disaese: 'AML',
          use_case: 'mnist',
          location: 'Test node',
          created_at: '2024-01-01',
          updated_at: '2024-01-02',
        },
        {
          name: 'MNIST',
          node_name: 'Node B',
          disease: 'AML',
          use_case: 'mnist',
          location: 'Health Data Center B',
          created_at: '2024-02-01',
          updated_at: '2024-02-02',
        },
  ]);

  const [nodes, setNodes] = useState([
    // Initial dummy data for models
    {
          name: 'Node A',
          location: 'HD Center 1',
          owner: 'HD Center 1',
          last_task_involved: 'Task 1',
          network: 'Network 1',
          created_at: '2024-01-01',
        },
        {
          name: 'Node B',
          location: 'HD Center 2',
          owner: 'HD Center 2',
          last_task_involved: 'Task 1',
          network: 'Network 1',
          created_at: '2024-01-01',
        },
        {
          name: 'Node C',
          location: 'HD Center 3',
          owner: 'HD Center 3',
          last_task_involved: 'Task 2',
          network: 'Network 2',
          created_at: '2024-01-01',
        },
        {
          name: 'Node D',
          location: 'HD Center 4',
          owner: 'HD Center 4',
          last_task_involved: 'Task 2',
          network: 'Network 2',
          created_at: '2024-01-01',
        },
  ]);


  const [isAdmin, setIsAdmin] = useState(false);

  const toggleAdminMode = () => {
    setIsAdmin(prev => !prev);
  };



  const addNewNode = (node) => {
    setNodes(prevNodes => {
      const updatedNodes = [...prevNodes, node];
      localStorage.setItem('nodes', JSON.stringify(updatedNodes));  // Update localStorage
      return updatedNodes;
    });
  };

  const deleteNode = (index) => {
    setNodes(prevNodes => {
      const updatedNodes = prevNodes.filter((_, i) => i !== index);
      localStorage.setItem('nodes', JSON.stringify(updatedNodes));  // Update localStorage
      return updatedNodes;
    });
  };

  console.log(process.env)

  return (
        <Router>
            <Layout isAdmin={isAdmin} toggleAdminMode={toggleAdminMode} >
                <Routes>
                  <Route index path="/" element={<Home />} />
                  <Route path="/models" element={<Models />} />
                  <Route path="/models/detail/:model_name" element={<ModelDetail />} />
                  <Route path="/models/upload" element={<UploadModel />} />
                  <Route path="/tasks" element={<Tasks  />} />
                  <Route path="/tasks/detail/:task_id" element={<TaskDetail />} />
                  <Route path="/tasks/mdetail/:task_id" element={<MnistTaskDetail />} />
                  <Route path="/tasks/new" element={<NewTask datasets={datasets} />} />
                  <Route path="/network" element={<Network nodes={nodes} addNode={addNewNode} deleteNode={deleteNode} isAdmin={isAdmin} />} />
                  <Route path="/network/register-node" element={<RegisterNode registerNode={addNewNode} />} />
                  <Route path="/datasets" element={<Datasets  datasets={datasets} />} />
                  <Route path="/profile" element={<Profile />} />
                  <Route path="/contact" element={<Contact />} />
                  <Route path="/about" element={<About />} />
                  <Route path="/help" element={<Help />} />
                </Routes>
            </Layout>
        </Router>
  );
}

export default App;

