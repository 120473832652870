import React, { useState } from 'react';

const Contact = () => {
  // State to store form data
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const [error, setError] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);

  // Handle form field changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  // Form submission handler
  const handleSubmit = (e) => {
    e.preventDefault();

    // Simple form validation
    if (!formData.name || !formData.email || !formData.message) {
      setError('All fields are required.');
      return;
    }

    // Reset error and mark form as submitted
    setError(null);
    setIsSubmitted(true);

    // Here you would usually send the form data to a backend server
    console.log('Form submitted:', formData);

    // Reset form data after submission
    setFormData({
      name: '',
      email: '',
      message: ''
    });
  };

  return (
    <div class="contact">
      <div class="task-section">
        <h1>Contact Us</h1>

        {isSubmitted ? (
          <div class="success-message">
            <p>Thank you for contacting us! We'll get back to you shortly.</p>
          </div>
        ) : (
          <form onSubmit={handleSubmit} class="task-form">
            {error && <p class="error-message">{error}</p>}

            <div class="form-group mt-10">
              <label class="color-primary" htmlFor="name">Full Name</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>

            <div class="form-group mt-10">
              <label class="color-primary" htmlFor="email">Email Address</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>

            <div class="color-primary" class="form-group mt10">
              <label htmlFor="message">Message</label>
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                required
              ></textarea>
            </div>

            <button type="submit" class="btn-primary mt-15">Submit</button>
          </form>
        )}
      </div>
    </div>
  );
};

export default Contact;