import React from 'react';
import { NavLink } from 'react-router-dom';
// import './Navbar.css';

const Navbar = ({ isAdmin, toggleAdminMode }) => {
  return (
      <nav className="navbar">

        <ul class="">
            <li class="w-10p ml-10 float-left"><img className="h-100p" src="/images/SYNTHEMA_Logo_Horizontal.png" alt="Logo"/></li>
            <li><NavLink exact to="/" activeClassName="active" href="#">Home</NavLink></li>
            <li><NavLink to="/models" activeClassName="active" href="#">Models</NavLink></li>
            <li><NavLink to="/tasks" activeClassName="active" href="#">Tasks</NavLink></li>
            <li><NavLink to="/network" activeClassName="active" href="#">Fed. Network</NavLink></li>
            <li><NavLink to="/datasets" activeClassName="active" href="#">Datasets</NavLink></li>
            <li><NavLink to="/profile" activeClassName="active" href="#">Profile</NavLink></li>
        </ul>
        <button onClick={toggleAdminMode} class="btn-yellow mr-10 h-30p p-5">
          {isAdmin ? "Admin" : "User"}
        </button>
      </nav>
  );
};

export default Navbar;
