import React from 'react';
import Navbar from './Navbar';
import Footer from './Footer';

const Layout = ({ children, isAdmin, toggleAdminMode }) => {
  return (
      <div class="app">
          <Navbar isAdmin={isAdmin} toggleAdminMode={toggleAdminMode} />
          <main class="content">{children}</main>
          <Footer />
      </div>
  );
};

export default Layout;
