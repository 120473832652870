import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import VersionsTable from '../parts/VersionsTable';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate } from "react-router-dom";
import { getModels, getModelVersions, deleteModelVersion, deleteModel } from '../../services/mlflowService';

const Models = () => {
  const navigate = useNavigate();
  const [models, setModels] = useState([]);
  const [modelVersions, setModelVersions] = useState({}); // Stores versions per model

  // Fetch models on component load
  useEffect(() => {
    const fetchModels = async () => {
      try {
        const modelsData = await getModels();
        setModels(modelsData);
        const versionsMap = {};

        for (let model of modelsData) {
          if (!modelVersions[model.name]) {
            try {
              const versions = await getModelVersions(model.name);
              versionsMap[model.name] = versions;
            } catch (error) {
              console.error("Error fetching model versions:", error);
            }
          }
        }

        setModelVersions(prev => ({ ...prev, ...versionsMap }));
        console.log("Updated modelVersions:", versionsMap);
      } catch (error) {
        console.error("Error fetching models:", error);
      }
    };

    fetchModels();
  }, []);

  const handleGoToUploadModel = () => {
    navigate('/models/upload');
  };

  const handleGoToModelDetail = (model) => {
    navigate(`/models/detail/${model.name}`, { state: { model } });
  };

  const handleDropdown = async (el, model) => {
    let elem = document.getElementById("det".concat(String(el)));
    if (elem) {
      if (elem.classList.contains("show")) {
        elem.classList.remove("show");
        elem.classList.add("hide");
      } else if (elem.classList.contains("hide")) {
        elem.classList.remove("hide");
        elem.classList.add("show");
      }
    }
  };

  const handleDeleteModelVersion = async (modelName, version) => {
    try {
      await deleteModelVersion(modelName, version);
      // Update state to remove the deleted version
      setModelVersions(prev => {
        const updatedVersions = { ...prev };
        updatedVersions[modelName] = updatedVersions[modelName].filter(v => v.version !== version);
        return updatedVersions;
      });
    } catch (error) {
      console.error("Error deleting model version:", error);
    }
  };

  const handleDeleteModel = async (modelName) => {
    try {
      await deleteModel(modelName); // Delete the entire model
      // Update the state to remove the deleted model
      setModels(prev => prev.filter(model => model.name !== modelName));
      setModelVersions(prev => {
        const updatedVersions = { ...prev };
        delete updatedVersions[modelName]; // Remove all versions for the deleted model
        return updatedVersions;
      });
      console.log(`Model '${modelName}' deleted successfully.`);
    } catch (error) {
      console.error("Error deleting model:", error);
    }
  };

  const handleEditModel = (model) => {
    navigate(`/models/edit/${model.name}`); // Navigate to edit page
  };

  return (
    <div class="models">
      <div class="container">
        <h1>Registered models</h1>
        <Button onClick={handleGoToUploadModel} class="btn-primary">Upload new Model</Button>

        <div class="search-bar">
          <input type="text" placeholder="<search model>" />
          <button>🔍</button>
        </div>

        <div class="mt-15 flex-column">
          {models.map((model, index) => (
            <div key={index}>
              <Button class="btn-primary-text-left" onClick={() => handleDropdown(index + 1, model)}>
                {model.name}
                <ArrowBackIosIcon class="arrow-icon-down hide" />
              </Button>
              <div id={`det${index + 1}`} class="model-item flex-row flex-space-between p-15 frame-primary card-back show">
                <div class="model-metadata">
                  <p><strong>Model Name:</strong> {model.name}</p>
                  <p><strong>Created At:</strong> {model.creation_timestamp}</p>
                  <p><strong>Updated At:</strong> {model.last_updated_timestamp}</p>
                  <p><strong>Use Case:</strong> </p>
                  <p><strong>Source:</strong> Upload by user</p>
                </div>

                <VersionsTable versions={modelVersions[model.name] || []} />

                <div class="actions ml-20">
                  <Button onClick={() => handleGoToModelDetail(model)} class="btn-skin">Go to</Button>
                  <Button onClick={() => handleEditModel(model)} class="btn-yellow hide">Edit</Button>
                  <Button onClick={() => handleDeleteModel(model.name)} class="btn-red mt-10">Delete</Button>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div class="pagination hide">
          <button>&laquo;</button>
          <button>1</button>
          <button>2</button>
          <button>3</button>
          <button>4</button>
          <button>5</button>
          <button>&raquo;</button>
        </div>
      </div>
    </div>
  );
};

export default Models;